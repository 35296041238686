<template>
  <Loading v-if="is_loading" />
  <b-row v-else-if="pageData" class="show-friend">
    <!-- right-side -->
    <b-col sm="12" md="9">
      <b-card
        body-class="pb-0"
        :img-src="filterImages(pageData.user.media, 'cover_image')"
        :img-alt="pageData.user.first_name"
        img-top
      >
        <b-avatar
          class="my-avatar"
          size="10rem"
          :img-alt="pageData.user.first_name"
          :src="filterImages(pageData.user.media, 'path')"
        />
        <b-card-title>
          {{ pageData.user.first_name }}
        </b-card-title>
        <b-row align-h="between">
          <b-col>
            <b-card-sub-title class="mt-0">
              {{ $t(pageData.user.user_type) }}
            </b-card-sub-title>
            <b-card-text v-if="pageData.eduyear">
              {{ pageData.eduyear.name }}
            </b-card-text>
          </b-col>
        </b-row>
        <hr />
        <!-- Start Tab Header -->
        <b-col cols="12" nav-class="mb-0 wrap">
          <div class="tab-header-scrollable">
            <b-tabs v-model="activeTab" style="overflow-x: auto">
              <b-tab
                v-for="(tab, index) in tabs"
                :key="index"
                :title="$t(`g.${tab.title}`)"
              ></b-tab>
            </b-tabs>
          </div>
        </b-col>
        <!-- End Tab Header -->
      </b-card>

      <!-- Start Tab Body -->
      <b-card>
        <b-tabs v-model="activeTab" nav-class="d-none">
          <b-tab v-for="(tab, index) in tabs" :key="index">
            <div class="tab-body-title">
              <h4>
                {{ $t(`g.${tab.title}`) }}
              </h4>
            </div>
            <hr />
            <profile-about-component
              v-if="tab.title == 'About'"
              :editable="true"
              :aboutProps="pageData.user.about"
            />
            <profile-enrolled-component
              v-if="tab.title == 'Courses'"
              :enrolled-props="pageData.enrollments"
            />
          </b-tab>
        </b-tabs>
      </b-card>
      <!-- End Tab Body -->
    </b-col>
    <!-- right-side -->

    <!-- left side -->
    <b-col sm="12" md="3">
      <profile-parents-card-component
        v-if="pageData.guardians && pageData.guardians.length"
        :parentsCardProps="pageData.guardians"
      />
      <friends-card-component
        :friendsCardProps="pageData.user.friends"
        @callUserData="getProfileData"
      />
    </b-col>
    <!-- left side -->

    <!-- overlay loading -->
    <overlay-component :isLoading="overlay_loading" />
    <!-- overlay loading -->
  </b-row>
</template>

<script>
import Loading from "@/components/loading/loading.vue";
import About from "@/components/profile/ProfileAboutComponent";
import OverlayComponent from "@/components/shared/OverlayComponent";
import FriendsCardComponent from "@/components/profile/FriendsCardComponent";
import ProfileParentsCardComponent from "@/components/profile/ProfileParentsCardComponent/index.vue";
import ProfileAboutComponent from "@/components/profile/ProfileAboutComponent";
import ProfileEnrolledComponent from "@/components/profile/ProfileEnrolledComponent/index.vue";

import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BCardSubTitle,
  BButton,
  BListGroupItem,
  BAvatar,
  BTabs,
  BTab,
  BCardTitle,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BTab,
    BCard,
    BTabs,
    About,
    Loading,
    BButton,
    BAvatar,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BListGroupItem,
    OverlayComponent,
    FriendsCardComponent,
    ProfileParentsCardComponent,
    ProfileAboutComponent,
    ProfileEnrolledComponent,
  },
  data() {
    return {
      user_id: null,
      is_loading: true,
      overlay_loading: false,
      pageData: null,
      activeTab: 0,
      profileImgaePlaceholder: require("@/assets/images/profileAvatar.png"),
      profileCoverPlaceholder: require("@/assets/images/profileCover.png"),
      tabs: [{ title: "About" }, { title: "Courses" }],
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.user_id = this.$route.params.id;
      this.getProfileData(this.$route.params.id);
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    // toast method
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },

    // get user profile data
    async getProfileData(id) {
      try {
        this.is_loading = true;
        const res = await this.$http.get(`/guardian/student/${id}`);
        this.pageData = res.data.data;
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },

    filterImages(data, name) {
      const placeholderImage =
        name === "path"
          ? this.profileImgaePlaceholder
          : this.profileCoverPlaceholder;

      if (data) {
        const matchingImage = data.find((image) => image.name === name);
        return matchingImage ? matchingImage.path : placeholderImage;
      }

      return placeholderImage;
    },

    // make friend request
  },
  watch: {
    $route: {
      deep: true,
      handler(newValue) {
        this.getProfileData(newValue.params.id);
      },
    },
  },
};
</script>

<style lang="scss">
@import "./showStudent.scss";
</style>
