<template>
  <div class="parent-card">
    <b-card :title="$t('g.Parents')" v-if="parentsCardProps">
      <hr />
      <b-row
        class="align-items-center mb-1"
        v-for="(parent, index) in parentsCardProps"
        :key="index"
      >
        <b-link
          @click="
            redirectUser(
              parent.user.user_type,
              parent.user.type_id,
              parent.user.email
            )
          "
        >
          <b-avatar
            class="mx-1"
            :src="
              parent.user.media && parent.user.media.length > 0
                ? parent.user.media[0].path
                : $helpers.uiAvatar(parent.user.first_name)
            "
          />
          <span class="user-name">{{
            `${parent.user.first_name} ${parent.user.last_name}`
          }}</span>
        </b-link>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BAvatar, BLink } from "bootstrap-vue";

export default {
  name: "ProfileParentsCardComponent",
  components: {
    BRow,
    BCard,
    BAvatar,
    BLink,
  },
  props: ["parentsCardProps"],
  data() {
    return {
      userEmail: null,
    };
  },
  created() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.userEmail = userData.email;
  },
  methods: {
    redirectUser(userType, id, email) {
      if (email === this.userEmail) {
        this.$router.push("/profile");
      } else if (userType === "guardian") {
        this.$router.push(`/show-parent/${id}`);
      }
    },
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
