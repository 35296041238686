<template>
  <div
    class="d-flex flex-column"
    style="gap: 23px"
    v-if="enrolledProps.length > 0"
  >
    <b-row
      align-h="start"
      v-for="(enroll, index) in enrolledProps"
      :key="index"
    >
      <b-col cols="auto">
        <img
          width="45"
          height="45"
          class="course-image"
          :alt="enroll.course_id.name"
          :src="
            enroll.course_id.image && enroll.course_id.image.length > 0
              ? enroll.course_id.image[0].path
              : imageSrc
          "
        />
      </b-col>
      <b-col class="p-0 my-1 mx-2" style="white-space: noWrap">
        <b-row>{{ enroll.course_id.name }}</b-row>
        <b-row style="white-space: noWrap">
          <span style="color: #f5822a">
            {{ `${enroll.progress}` }}
          </span>
          {{ $t("g.progress") }}
          <span style="color: #f5822a">
            {{ ` . ${enroll.score}` }}
          </span>
          {{ ` ${$t("g.score")}` }}
        </b-row>
      </b-col>
    </b-row>
  </div>
  <b-card-text class="mb-0" v-else>
    {{ $t("g.ThereIsCurrentlyNoEnrolledCourses") }}
  </b-card-text>
</template>

<script>
import { BCardText, BLink, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
    BCardText,
    BLink,
  },
  props: ["enrolledProps"],
  data() {
    return {
      imageSrc: require("@/assets/dev/Rectangle 12.png"),
      plusIcon: require("@/assets/images/icons/plus.svg"),
    };
  },
};
</script>
<style scoped>
@import "./index.scss";
</style>
